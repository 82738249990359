import { Box, styled, useTheme, Button } from '@mui/material'
import { Control } from 'react-hook-form'

import { meetingsOptions } from '../../../constants'
import { FindRoomFormValues, TOption } from '../../../types'
import {
  ContactsAutocomplete,
  DatePicker,
  DurationToggle,
  MeetingTypeAutocomplete,
  RoomSizeToggle,
  TimeSelect,
} from '../../molecules'

const Row = styled('div')({
  display: 'flex',
  flexDirection: 'row',
})

type Props = {
  currentDate: Date
  control: Control<FindRoomFormValues>
  contactOptions: TOption[]
  userStep: number
  setSelfContact?: () => void
}

export const FindRoomForm = ({
  control,
  currentDate,
  contactOptions,
  userStep,
  setSelfContact,
}: Props) => {
  const theme = useTheme()

  return (
    <>
      <Row>
        <DatePicker currentDate={currentDate} control={control} label="Дата" />
        <Box width={theme.spacing(2)} />
        <TimeSelect
          label="Время"
          currentDate={currentDate}
          control={control}
          userStep={userStep}
        />
      </Row>
      <Box height={theme.spacing(2)} />
      <Row>
        <RoomSizeToggle control={control} />
      </Row>
      <Box height={theme.spacing(2)} />
      <Row>
        <DurationToggle control={control} />
      </Row>
      <Box height={theme.spacing(2)} />
      <Row>
        <ContactsAutocomplete control={control} options={contactOptions} />
      </Row>
      <Row>
        <Button onClick={setSelfContact} size="small" variant="text">
          Добавить себя
        </Button>
      </Row>
      <Box height={theme.spacing(2)} />
      <Row>
        <MeetingTypeAutocomplete control={control} options={meetingsOptions} />
      </Row>
    </>
  )
}
