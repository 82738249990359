import { Autocomplete, TextField } from '@mui/material'
import { Control, Controller } from 'react-hook-form'

import { FindRoomFormValues } from '../../../types'

type Props = {
  control: Control<FindRoomFormValues>
  options: string[]
}

export const MeetingTypeAutocomplete = ({ control, options }: Props) => {
  return (
    <Controller
      control={control}
      name="meetingType"
      render={({ field: { value, onChange } }) => {
        return (
          <Autocomplete
            style={{ flex: 1 }}
            filterSelectedOptions
            freeSolo
            autoSelect
            options={options}
            value={value}
            onChange={(_, meetingType) => onChange(meetingType)}
            renderInput={params => (
              <TextField
                {...params}
                label="Тип встречи"
                placeholder="Введите тип встречи"
              />
            )}
          />
        )
      }}
    />
  )
}
