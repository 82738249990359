import { Modal, Typography, Box } from '@mui/material'

type Props = {
  isVisible: boolean
  setIsVisible: (isVisible: boolean) => void
}

export const ModalRoomSizes = ({ isVisible, setIsVisible }: Props) => {
  return (
    <Modal
      open={isVisible}
      onClose={() => setIsVisible(false)}
      style={{
        alignItems: 'center',
        justifyContent: 'space-around',
        display: 'flex',
      }}
    >
      <Box
        style={{
          backgroundColor: 'white',
          width: '90%',
          height: 'auto',
          padding: 20,
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Размеры переговорок
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <strong>Индивидуальные переговорки (1-2 человека):</strong>
          <br />
          Переговорки #1, #2, #3, #4, #5, #6, #7
          <br />
          <br />
          <strong>Средние переговорки:</strong>
          <br />
          Квадраты — 4-6 человек
          <br />
          Облака — 4-6 человек
          <br />
          Космос — 6-8 человек
          <br />
          Библиотека — 6-8 человек
          <br />
          <br />
          <strong>Большие переговорки:</strong>
          <br />
          Самолёт — 6-10 человек
          <br />
          Зелёная — 10-12 человек
          <br />
          Прованс — 8-10 человек
          <br />
          Лекторий — 20-60 человек
        </Typography>
      </Box>
    </Modal>
  )
}
