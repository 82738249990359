import { CALENDAR_GROUP_NAME } from '@shared/api/constants'
import { getContactGroup } from '@shared/api/methods'
import { TGetContactGroupsResponse } from '@shared/api/methods/types'
import { calendarKeys } from '@shared/api/query-keys'
import { QueryOptions } from '@shared/api/types'
import { useQuery } from '@tanstack/react-query'

export const useContactGroups = (
  options?: QueryOptions<
    TGetContactGroupsResponse,
    ReturnType<typeof calendarKeys.contactGroups>
  >,
) =>
  useQuery(calendarKeys.contactGroups(), () => getContactGroup(), {
    staleTime: Infinity,
    ...options,
    select: data => ({
      ...data,
      data: {
        ...data.data,
        resourceName: data?.data?.contactGroups?.find(
          (group: { name: string; resourceName: string }) =>
            group.name === CALENDAR_GROUP_NAME,
        )?.resourceName,
      },
    }),
  })
