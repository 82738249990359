import { Box, Skeleton } from '@mui/material'

export const RoomCardSkeleton = () => (
  <>
    {Array.from(new Array(3), (_, index) => (
      <Box
        key={index}
        sx={{ width: '100%', height: '168px', marginBottom: '16px' }}
      >
        <Skeleton
          height="100%"
          style={{ transform: 'none' }}
          animation="wave"
        />
      </Box>
    ))}
  </>
)
