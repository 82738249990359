import { API_KEY, CALENDAR_ID } from '@shared/api/constants'
import { googleContentInstance } from '@shared/api/intances'
import { request } from '@shared/api/request'

type TGetEventsPayload = {
  dayStart: string
  dayEnd: string
}

export const getEvents = ({ dayStart, dayEnd }: TGetEventsPayload) =>
  request(googleContentInstance, {
    url: `/calendar/v3/calendars/${CALENDAR_ID}/events`,
    method: 'GET',
    params: {
      timeMax: dayEnd,
      timeMin: dayStart,
      key: API_KEY,
    },
  })
