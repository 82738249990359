import { TEmail } from '@shared/lib'

import { TOption } from '../types'

export const mapContactsToEmails = (contacts: TOption[]): TEmail[] => {
  return contacts
    .map(item => ({
      email: item?.value?.trim() || '',
      name: item.label?.trim() || '',
    }))
    .filter(item => item.email)
}
