import { setToken } from '@features/auth'
import { startSessionInterceptor } from '@features/auth/session-interceptor'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { googleLogout, GoogleOAuthProvider } from '@react-oauth/google'
import { OAUTH_CLIENT_ID } from '@shared/api/constants'
import {
  googleContentInstance,
  googleContentPeopleInstance,
  googlePeopleInstance,
} from '@shared/api/intances'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import ruLocale from 'date-fns/locale/ru'

import { ThemeProvider } from '../../../shared/ui'

import { App } from './app'

const queryClient = new QueryClient()

const logout = () => {
  googleLogout()
  setToken(null)
  queryClient.clear()
}

startSessionInterceptor({
  invalidTokenStatusCodes: [401],
  onInvalidToken: () => logout(),
  onUnhandledError: e => {
    console.log('unhandledError', e)
  },
})([googleContentInstance, googleContentPeopleInstance, googlePeopleInstance])

export const AppConnector = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <GoogleOAuthProvider clientId={OAUTH_CLIENT_ID}>
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={ruLocale}
        >
          <ThemeProvider>
            <App />
          </ThemeProvider>
        </LocalizationProvider>
      </GoogleOAuthProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
