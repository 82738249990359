import { isSameDay } from '@shared/lib'
import { add, format, isBefore, set } from 'date-fns'

import { DAY_END_NUM, DAY_START_NUM } from '../constants'

import { roundToNearestAvailableTime } from './round-to-nearest-available-time'

type Args = {
  currentDate: Date
  start: Date
  step: number
}

export const getTimeBySteps = ({ currentDate, start, step }: Args) => {
  const startTimeDate = isSameDay(start, currentDate)
    ? roundToNearestAvailableTime(start, step)
    : set(start, { hours: DAY_START_NUM, minutes: 0, seconds: 0 })

  const endTimeDate = set(start, {
    hours: DAY_END_NUM,
    minutes: 0,
    seconds: 1,
  })

  let indexDate = startTimeDate
  const options: string[] = []

  while (isBefore(indexDate, endTimeDate)) {
    const formattedOption = format(indexDate, 'HH:mm')
    options.push(formattedOption)
    const incrementedDate = add(indexDate, { minutes: step })
    indexDate = incrementedDate
  }

  return options
}
