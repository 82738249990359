import {
  // CalendarTodayRounded,
  ExitToAppRounded,
  Forward30Rounded,
  InfoRounded,
  WarningRounded,
  MonetizationOn,
  Message,
} from '@mui/icons-material'
import {
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'

type Props = {
  sizesAction: () => void
  stepAction: () => void
  rulesAction: () => void
  loginLogoutAction: () => void
  richAction: () => void
  isLoggedIn: boolean
}

export const NavList = ({
  sizesAction,
  stepAction,
  rulesAction,
  loginLogoutAction,
  richAction,
  isLoggedIn,
}: Props) => {
  return (
    <List component="nav">
      {/* <ListItemButton>
        <ListItemIcon>
          <CalendarTodayRounded />
        </ListItemIcon>
        <ListItemText primary="Бронирование" />
      </ListItemButton> */}
      <ListItemButton onClick={sizesAction}>
        <ListItemIcon>
          <InfoRounded />
        </ListItemIcon>
        <ListItemText primary="Размеры переговорок" />
      </ListItemButton>
      <ListItemButton onClick={stepAction}>
        <ListItemIcon>
          <Forward30Rounded />
        </ListItemIcon>
        <ListItemText primary="Шаг встреч" />
      </ListItemButton>
      <Divider sx={{ my: 1 }} />
      <ListItemButton onClick={rulesAction}>
        <ListItemIcon>
          <WarningRounded />
        </ListItemIcon>
        <ListItemText primary="Правила бронирования" />
      </ListItemButton>
      <ListItemButton onClick={richAction}>
        <ListItemIcon>
          <Message />
        </ListItemIcon>
        <ListItemText primary="Обратная связь" />
      </ListItemButton>
      <ListItemButton onClick={richAction}>
        <ListItemIcon>
          <MonetizationOn />
        </ListItemIcon>
        <ListItemText primary="Поддержать разработчиков" />
      </ListItemButton>
      <Divider sx={{ my: 1 }} />
      <ListItemButton onClick={loginLogoutAction}>
        <ListItemIcon>
          <ExitToAppRounded />
        </ListItemIcon>
        <ListItemText
          primary={isLoggedIn ? 'Выход' : 'Вход с помощью Google'}
        />
      </ListItemButton>
    </List>
  )
}
