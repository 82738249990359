import { getContactAddresses } from '@shared/api/methods'
import { TGetContactsResponse, TResponse } from '@shared/api/methods/types'
import { calendarKeys } from '@shared/api/query-keys'
import { GooglePerson } from '@shared/lib'
import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'

export type QueryOptions<
  TResponse,
  TSelectedResponse,
  TKeys extends QueryKey,
> = UseQueryOptions<
  Promise<AxiosResponse<TResponse>[]>,
  unknown,
  TSelectedResponse,
  TKeys
>

type SelectedResponse = {
  data: {
    contacts: {
      value: string
      label: string
    }[]
  }
}
export const useContacts = (
  resultArray: string[],
  options?: QueryOptions<
    TGetContactsResponse,
    SelectedResponse,
    ReturnType<typeof calendarKeys.contacts>
  >,
) => {
  const fetcher = () =>
    Promise.all(resultArray.map(searchStr => getContactAddresses(searchStr)))

  return useQuery(calendarKeys.contacts(resultArray), fetcher, {
    staleTime: Infinity,
    ...options,
    select: data => {
      const contacts = Object.values(data).reduce<TResponse[]>((acc, item) => {
        return [...acc, ...item.data.responses]
      }, [])

      const contactsResult = contacts
        .reduce<GooglePerson[]>((acc, cur) => [...acc, cur.person], [])
        .map(contact => ({
          value:
            contact.emailAddresses.find(
              email => email.value.indexOf('gmail.com') !== 1,
            )?.value ?? '',
          label: `${contact.names[0].displayName} (${
            contact.emailAddresses.find(
              email => email.value.indexOf('gmail.com') !== 1,
            )?.value
          })`,
        }))

      return {
        data: {
          contacts: contactsResult,
        },
      }
    },
  })
}
