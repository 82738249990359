import { googlePeopleInstance } from '@shared/api/intances'
import { request } from '@shared/api/request'

export const getContacts = (resourceName: string) =>
  request(googlePeopleInstance, {
    url: `/v1/${resourceName}`,
    params: {
      maxMembers: '1000',
    },
  })
