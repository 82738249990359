import {
  Modal,
  Typography,
  Box,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
} from '@mui/material'

type Props = {
  isVisible: boolean
  setIsVisible: (isVisible: boolean) => void
  value: number
  setValue: (value: number) => void
}

export const ModalStep = ({
  isVisible,
  setIsVisible,
  value,
  setValue,
}: Props) => {
  return (
    <Modal
      open={isVisible}
      onClose={() => setIsVisible(false)}
      style={{
        alignItems: 'center',
        justifyContent: 'space-around',
        display: 'flex',
      }}
    >
      <Box
        style={{
          backgroundColor: 'white',
          width: '90%',
          height: 'auto',
          padding: 20,
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Шаг встреч
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          <FormControl>
            <RadioGroup
              defaultValue={30}
              value={value}
              name="radio-buttons-group"
              onChange={(_, value: string) => setValue(Number(value))}
            >
              <FormControlLabel
                value={15}
                control={<Radio />}
                label="15 минут"
              />
              <FormControlLabel
                value={30}
                control={<Radio />}
                label="30 минут"
              />
              <FormControlLabel
                value={60}
                control={<Radio />}
                label="60 минут"
              />
            </RadioGroup>
          </FormControl>
        </Typography>
      </Box>
    </Modal>
  )
}
