import { STEP } from '@features/find-room/constants'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { useEffect, useState } from 'react'
import { Control, Controller, useWatch, useController } from 'react-hook-form'

import { getTimeBySteps } from '../../../lib'
import { FindRoomFormValues } from '../../../types'

import {
  getStartTimeSiblings,
  getStartTimeSiblingsValues,
  getFormattedInMinutes,
} from './utils'

type Props = {
  currentDate: Date
  control: Control<FindRoomFormValues>
  label?: string
  userStep: number
}

export const TimeSelect = ({
  currentDate,
  control,
  label,
  userStep,
}: Props) => {
  const [timeOptions, setTimeOptions] = useState<string[]>([])

  const { date, startTime } = useWatch({ control })
  const { field } = useController({ control, name: 'startTime' })

  const step = STEP > userStep ? userStep : STEP

  useEffect(() => {
    const startDate = date ?? currentDate

    const timeOptions = getTimeBySteps({
      currentDate,
      start: startDate,
      step,
    })

    setTimeOptions(timeOptions)
  }, [date, step])

  const setSiblingValue = (bottom: string | null, top: string | null) => {
    if (startTime && !timeOptions.includes(startTime)) {
      if (bottom !== startTime && bottom) {
        field.onChange(bottom)
        return
      }
      if (top) {
        field.onChange(top)
      }
    }
  }

  useEffect(() => {
    if (!timeOptions.length) {
      return
    }

    const startTimeInMinutes = getFormattedInMinutes(startTime ?? '00:00')

    const { diffArray, ...siblings } = getStartTimeSiblings(
      timeOptions,
      startTimeInMinutes,
    )
    const { bottom, top } = getStartTimeSiblingsValues({
      diffArray,
      siblings,
      timeOptions,
    })

    setSiblingValue(bottom, top)
  }, [startTime, timeOptions])

  return (
    <Controller
      control={control}
      name="startTime"
      render={({ field: { onChange, value } }) => (
        <FormControl style={{ flex: 1 }}>
          <InputLabel>{label}</InputLabel>
          <Select
            label={label}
            value={value}
            onChange={onChange}
            placeholder="Время начала"
          >
            {timeOptions.map(option => (
              <MenuItem value={option} key={option}>
                {'с ' + option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    />
  )
}
