import { getFormattedInMinutes } from './get-formatted-time-in-minutes'

export const getStartTimeSiblings = (
  timeOptions: string[],
  startTimeInMinutes: number,
) => {
  const diffArray = timeOptions.map(
    time => getFormattedInMinutes(time) - startTimeInMinutes,
  )
  return {
    diffArray,
    ...diffArray.reduce(
      ({ top, bottom }, diff) => {
        if (diff < 0 && diff > bottom) {
          return {
            top,
            bottom: diff,
          }
        }

        if (diff > 0 && diff < top) {
          return {
            bottom,
            top: diff,
          }
        }

        return { top, bottom }
      },
      {
        bottom: diffArray[0],
        top: diffArray[diffArray.length - 1],
      },
    ),
  }
}
