import { createStore, createEvent } from 'effector'
import { persist } from 'effector-storage/local'

export const $token = createStore<string | null>(null)

persist({ store: $token, key: 'authToken' })

export const setToken = createEvent<string | null>()

$token.on(setToken, (_, payload) => payload)
