import { $token, setToken } from '@features/auth'
import {
  FindRoomForm,
  FindRoomFormValues,
  mapContactsToEmails,
  mapRoomSizeToPeopleNum,
  roundToNearestAvailableTime,
  DAY_END,
  DAY_END_TIME,
  DAY_START,
  DAY_START_NUM,
  defaultFindRoomFormValues,
  ModalRoomSizes,
  ModalStep,
} from '@features/find-room'
import { NavList } from '@features/find-room/ui/molecules'
import { RoomCardsList } from '@features/find-room/ui/organisms'
// import { $isFirstVisit, setIsFirstVisit } from '@features/first-visit'
import { Button } from '@mui/material'
import { useGoogleLogin, googleLogout } from '@react-oauth/google'
import { CALENDAR_ID } from '@shared/api/constants'
import {
  useContactGroups,
  useEvents,
  useMemberNames,
  useContacts,
  useMe,
} from '@shared/api/hooks'
import {
  rooms,
  getEmptyRoomsByDay,
  GetEmptyRoomsByDayResult,
  getKldDate,
  isSameDay,
} from '@shared/lib'
import { PageTemplate } from '@shared/ui/templates/page-template'
import { format, set } from 'date-fns'
import { useEvent, useStore } from 'effector-react'
import { useState, useCallback, useEffect, useMemo } from 'react'
import { useForm, useWatch } from 'react-hook-form'

// import { Backlog } from '../backlog'
import { Description } from '../description'

export function App() {
  const [isVisibleSizesModal, setIsVisibleSizesModal] = useState(false)
  const [isVisibleStepModal, setIsVisibleStepModal] = useState(false)

  const currentDate = getKldDate()

  const token = useStore($token)
  // const isFirstVisit = useStore($isFirstVisit)
  const setTokenHandler = useEvent(setToken)
  const [result, setResult] = useState<GetEmptyRoomsByDayResult[]>([])

  const [step, setStep] = useState(30)

  const { control, setValue } = useForm<FindRoomFormValues>({
    defaultValues: defaultFindRoomFormValues,
  })
  const form = useWatch({ control })
  const selectedDate = useMemo(() => form.date ?? currentDate, [form])
  const startTime = useMemo(() => form.startTime ?? DAY_START, [form])
  const duration = useMemo(() => form.duration ?? 15 * 60 * 1000, [form])
  // Временный фикс, нужно искать переговорки в зависимости от ее размера (roomSize), а не вместимости(peopleNum)
  const peopleNum = useMemo(
    () => mapRoomSizeToPeopleNum[form.roomSize ?? 'small'],
    [form],
  )
  const emails = useMemo(
    () => mapContactsToEmails(form.selectedContacts ?? []),
    [form],
  )

  useEffect(() => {
    const possibleEventDate = isSameDay(selectedDate, currentDate)
      ? selectedDate
      : set(selectedDate, { hours: DAY_START_NUM, minutes: 0, seconds: 0 })

    const firstAvailableTime = isSameDay(selectedDate, currentDate)
      ? roundToNearestAvailableTime(possibleEventDate, step)
      : set(selectedDate, { hours: DAY_START_NUM, minutes: 0, seconds: 0 })

    setValue('startTime', format(firstAvailableTime, 'HH:mm'))
  }, [selectedDate, setValue])

  const createTimeString = useCallback(
    (time: string) => {
      return `${selectedDate.toISOString().split('T')[0]}T${time}:00+02:00`
    },
    [selectedDate],
  )

  const {
    data: eventsData,
    isLoading: isLoadingEvents,
    remove: removeEvents,
  } = useEvents(
    {
      dayStart: createTimeString(DAY_START),
      dayEnd: createTimeString(DAY_END),
    },
    {
      enabled: Boolean(token),
    },
  )

  const events = eventsData?.data?.items ?? []

  const { data: dataContactGroups, remove: removeContactGroups } =
    useContactGroups({
      enabled: Boolean(token),
    })

  const resourceName = dataContactGroups?.data?.resourceName ?? ''

  const { data: dataContacts } = useMemberNames(resourceName, {
    enabled: Boolean(resourceName),
  })

  const contactsRequests = dataContacts?.data?.ids ?? []

  const { data: contactsData } = useContacts(contactsRequests, {
    enabled: Boolean(contactsRequests.length),
  })

  const availableContacts = contactsData?.data?.contacts ?? []

  const login = useGoogleLogin({
    onSuccess: token => {
      if (token) {
        setTokenHandler(token.access_token)
        // setIsFirstVisit(false)
      }
    },
    scope:
      'https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/contacts.readonly',
  })

  const logout = useCallback(() => {
    setTokenHandler(null)
    setResult([])
    removeEvents()
    removeContactGroups()
    googleLogout()
  }, [])
  const updateEmptyRoomsByDay = useCallback(() => {
    if (events.length) {
      setResult(
        getEmptyRoomsByDay({
          rooms,
          events,
          peopleNum,
          date: selectedDate.toISOString().split('T')[0],
          startTime,
          duration,
          endOfDayTime: DAY_END_TIME,
          step: step * 60 * 1000,
          emails,
        }),
      )
    }
  }, [events, selectedDate, duration, emails, peopleNum, startTime, step])

  const me = useMe()

  const setSelfContact = useCallback(() => {
    const userEmail = me.data?.data.emailAddresses.find(
      value => value.metadata.source.type === 'ACCOUNT',
    )?.value
    const userName = me.data?.data.names[0].displayName

    if (
      form.selectedContacts &&
      userName &&
      userEmail &&
      !form.selectedContacts?.find(contact => contact.value === userEmail)
    ) {
      setValue('selectedContacts', [
        ...form.selectedContacts,
        { label: userName, value: userEmail },
      ])
    }
  }, [me, form.selectedContacts])

  useEffect(() => {
    updateEmptyRoomsByDay()
  }, [events, updateEmptyRoomsByDay, form])

  return (
    <PageTemplate
      drawerContent={
        <NavList
          sizesAction={() => setIsVisibleSizesModal(true)}
          stepAction={() => setIsVisibleStepModal(true)}
          rulesAction={() =>
            window.open(
              'https://confa.kode.ru/pages/viewpage.action?pageId=34505442',
            )
          }
          richAction={() => window.open('https://www.tinkoff.ru/cf/ORFfR59UCm')}
          loginLogoutAction={token ? logout : login}
          isLoggedIn={Boolean(token)}
        />
      }
    >
      <div className="App">
        {token ? (
          <div>
            <br />
            <FindRoomForm
              setSelfContact={setSelfContact}
              userStep={step}
              currentDate={currentDate}
              control={control}
              contactOptions={availableContacts}
            />
            <RoomCardsList
              date={selectedDate}
              currentDate={currentDate}
              roomList={result}
              calendarId={CALENDAR_ID}
              emails={emails}
              isLoading={isLoadingEvents}
              meetingType={form.meetingType ?? ''}
            />
          </div>
        ) : (
          <>
            <Button
              variant="contained"
              color="primary"
              size="large"
              href="https://hr.kode.ru/dom"
            >
              Перейти в HRM
            </Button>
            <Description isFirstVisit={true} />
          </>
        )}
      </div>
      <ModalRoomSizes
        isVisible={isVisibleSizesModal}
        setIsVisible={setIsVisibleSizesModal}
      />
      <ModalStep
        value={step}
        setValue={setStep}
        isVisible={isVisibleStepModal}
        setIsVisible={setIsVisibleStepModal}
      />
    </PageTemplate>
  )
}
