import { getKldDate } from '@shared/lib'
import { format } from 'date-fns'

import { roundToNearestAvailableTime } from './lib'
import { FindRoomFormValues, TRoomSize } from './types'

// TODO: получать эти значения через функцию, форматируя константы ниже
export const DAY_START = '08:00'
export const DAY_END = '22:00'
export const DAY_END_TIME = '20:00'

/**
 * Начало рабочего дня в часах
 */
export const DAY_START_NUM = 8
/**
 * Конец рабочего дня в часах
 */
export const DAY_END_NUM = 20

/**
 * Шаг времени в минутах
 */
export const STEP = 30

/**
 * Опции длительности в минутах
 */
export const durationOptions = [15, 30, 45, 60, 90, 120]

/**
 * Размеры переговорок
 */
export const roomSizes: { size: TRoomSize; title: string }[] = [
  { size: 'small', title: 'маленькая' },
  { size: 'medium', title: 'средняя' },
  { size: 'large', title: 'большая' },
]

/**
 * Первоначальная настройка формы
 */
export const defaultFindRoomFormValues: FindRoomFormValues = {
  date: getKldDate(),
  duration: 30 * 60 * 1000,
  roomSize: 'small',
  selectedContacts: [],
  startTime: format(roundToNearestAvailableTime(getKldDate(), STEP), 'HH:mm'),
  meetingType: '',
}

export const meetingsOptions = [
  'Синк',
  'Интервью',
  'Ассессмент',
  'Performance Review',
  'PR lite',
  'Завершение ИС',
  'o3',
]
