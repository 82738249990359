import type { Room } from './rooms'

type Duration = number

type EventDateTime = {
  dateTime: string
  timeZone: string
}

export type CalendarEvent = {
  summary?: string
  start?: EventDateTime
  end?: EventDateTime
  location?: string
  id: string
  attendees?: {
    email: string
    responseStatus: string
    optional?: boolean
  }[]
  [key: string]: unknown
}

export type GooglePerson = {
  resourceName: string
  emailAddresses: {
    value: string
    metadata: {
      source: {
        type: string
      }
    }
  }[]
  names: {
    displayName: string
  }[]
  photos: {
    url: string
  }[]
}
export type TEmail = {
  name: string
  email: string
}

type GetEmptyRoomsByTimeProps = {
  events: CalendarEvent[]
  rooms: Room[]
  peopleNum: number
  date: string
  startTime: string
  duration: Duration
  emails: TEmail[]
}

type GetEmptyRoomsByDayProps = {
  events: CalendarEvent[]
  rooms: Room[]
  peopleNum: number
  date: string
  startTime: string
  duration: Duration
  endOfDayTime: string
  step: number
  emails: TEmail[]
}

export type GetEmptyRoomsByDayResult = {
  startTime: string
  endTime: string
  rooms: Room[]
  attendees: string[]
}

const addTime = (dt: string, duration: number) => {
  const date = dt.split('T')[0]
  const tmpDate = new Date(dt + 'Z')
  const dateEnd = new Date(+tmpDate + duration)
  return `${date}T${dateEnd.toISOString().split('T')[1].split('.')[0]}` // YYYY-MM-DDTHH-MM-SS
}

const getEmptyRoomsByTime = ({
  rooms,
  peopleNum,
  events,
  date,
  startTime,
  duration,
  emails,
}: GetEmptyRoomsByTimeProps): GetEmptyRoomsByDayResult | null => {
  const capableRooms = rooms.filter(room => {
    return room.capacity >= peopleNum
  })
  const emailsArr = emails.map(item => item.email)
  const plannedStartDT = `${date}T${startTime}` // YYYY-MM-DDTHH-MM-SS
  const plannedEndDT = addTime(plannedStartDT, duration)
  const crossEvents = events.filter(event => {
    if (
      !event.start?.dateTime ||
      !event.end?.dateTime ||
      !event.location ||
      !event.summary
    ) {
      return false
    }

    const eventStartDT = `${date}T${
      event.start.dateTime.split('T')[1].split('+')[0]
    }` // YYYY-MM-DDTHH-MM-SS
    const eventEndDT = `${date}T${
      event.end.dateTime.split('T')[1].split('+')[0]
    }` // YYYY-MM-DDTHH-MM-SS

    if (eventStartDT >= plannedStartDT && eventStartDT < plannedEndDT) {
      return true
    }
    if (eventEndDT > plannedStartDT && eventEndDT < plannedEndDT) {
      return true
    }
    if (eventStartDT <= plannedStartDT && eventEndDT > plannedStartDT) {
      return true
    }
    return false
  })

  const emailEvent = crossEvents.filter(event => {
    const attendee = event.attendees?.find(
      ({ email, responseStatus, optional }) => {
        return (
          emailsArr.includes(email) &&
          responseStatus !== 'declined' &&
          optional !== true
        )
      },
    )
    if (attendee) return true
    return false
  })

  const availableRooms = capableRooms.filter(room => {
    for (let eventIndex = 0; eventIndex < crossEvents.length; eventIndex++) {
      for (let aliasIndex = 0; aliasIndex < room.aliases.length; aliasIndex++) {
        if (
          crossEvents[eventIndex].location
            ?.toLowerCase()
            .trim()
            .indexOf(room.aliases[aliasIndex]) !== -1 ||
          crossEvents[eventIndex].location?.toLowerCase().trim() ===
            room.exactMatchName
        ) {
          return false
        }
      }
    }
    return true
  })
  const result: GetEmptyRoomsByDayResult = {
    startTime: '',
    endTime: '',
    rooms: [],
    attendees: [],
  }
  if (availableRooms.length) {
    result.startTime = startTime.substring(0, 5)
    result.endTime = plannedEndDT.split('T')[1].substring(0, 5)

    const attendeesSet = new Set<string>()
    if (emailEvent.length > 0) {
      emailEvent.forEach(event => {
        event.attendees?.forEach(attendee => {
          if (emailsArr.includes(attendee.email)) {
            attendeesSet.add(
              `${attendee.email} on ${event.summary} in ${
                event.location
              } at ${event.start?.dateTime.split('T')[1].substring(0, 5)}\n`,
            )
          }
        })
      })
    }
    result.attendees = Array.from(attendeesSet)
    result.rooms = availableRooms.map(room => {
      return room
    })

    return result
  }
  return null
}

export const getEmptyRoomsByDay = ({
  date,
  startTime,
  endOfDayTime,
  events,
  rooms,
  duration,
  peopleNum,
  step,
  emails,
}: GetEmptyRoomsByDayProps): GetEmptyRoomsByDayResult[] => {
  let startSearhDT = `${date}T${startTime}:00`
  const endOfDayDT = `${date}T${endOfDayTime}`
  const result: GetEmptyRoomsByDayResult[] = []
  while (startSearhDT < endOfDayDT) {
    const r = getEmptyRoomsByTime({
      events,
      rooms,
      date,
      duration,
      peopleNum,
      startTime: startSearhDT.split('T')[1],
      emails,
    })
    if (r) {
      result.push(r)
    }
    startSearhDT = addTime(startSearhDT, step)
  }
  return result
}
