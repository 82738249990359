import { roomSizes } from '@features/find-room/constants'
import { styled, ToggleButton, ToggleButtonGroup } from '@mui/material'
import React from 'react'
import { Control, Controller } from 'react-hook-form'

import { FindRoomFormValues } from '../../../types'

const StyledToggleButton = styled(ToggleButton)({
  flex: 1,
  textTransform: 'uppercase',
})

type Props = {
  control: Control<FindRoomFormValues>
}

export const RoomSizeToggle = ({ control }: Props) => {
  return (
    <Controller
      control={control}
      name="roomSize"
      render={({ field: { value, onChange } }) => (
        <ToggleButtonGroup
          value={value}
          exclusive
          onChange={onChange}
          style={{ flex: 1 }}
        >
          {roomSizes.map(roomSize => (
            <StyledToggleButton
              key={roomSize.title}
              color="primary"
              value={roomSize.size}
            >
              {roomSize.title}
            </StyledToggleButton>
          ))}
        </ToggleButtonGroup>
      )}
    />
  )
}
