import { getEvents } from '@shared/api/methods'
import { TGetEventsResponse } from '@shared/api/methods/types'
import { calendarKeys } from '@shared/api/query-keys'
import { QueryOptions } from '@shared/api/types'
import { useQuery } from '@tanstack/react-query'

type TPayload = {
  dayStart: string
  dayEnd: string
}

export const useEvents = (
  { dayStart, dayEnd }: TPayload,
  options?: QueryOptions<
    TGetEventsResponse,
    ReturnType<typeof calendarKeys.events>
  >,
) =>
  useQuery(
    calendarKeys.events(dayStart, dayEnd),
    () => getEvents({ dayStart, dayEnd }),
    {
      refetchOnWindowFocus: true,
      ...options,
    },
  )
