import { TEmail } from '@shared/lib'

export type TMakeBookingLinkArg = {
  calendarId: string
  selectedDate: Date
  startTime: string
  endTime: string
  roomName: string
  emails: TEmail[]
  meetingType: string
}

export const makeBookingLink = ({
  calendarId,
  emails,
  endTime,
  roomName,
  selectedDate,
  startTime,
  meetingType,
}: TMakeBookingLinkArg) => {
  const names: string[] = []
  const emailsList: string[] = []

  emails.forEach(item => {
    const nameChunks = item.name.split(' ')
    names.push(nameChunks[0])
    emailsList.push(item.email)
  })

  let title = meetingType || 'Синк'

  if (names.length > 0 && names?.length <= 4) {
    title += ': ' + names.join(', ')
  }

  if (names.length > 4) {
    title += ': ' + names.slice(0, 4).join(', ') + ` и другие`
  }

  // Reference https://github.com/InteractionDesignFoundation/add-event-to-calendar-docs/blob/main/services/google.md
  const chunks = [
    'https://calendar.google.com/calendar/render?',
    'action=TEMPLATE',
    `text=${encodeURIComponent(title)}`,
    `dates=${selectedDate
      .toISOString()
      .split('T')[0]
      .replaceAll('-', '')}T${startTime.replaceAll(':', '')}00/${selectedDate
      .toISOString()
      .split('T')[0]
      .replaceAll('-', '')}T${endTime.replaceAll(':', '')}00`,
    'ctz=Europe/Kaliningrad',
    `details=${encodeURIComponent('Создано в приложении «Доходный дом»')}`,
    `location=${encodeURIComponent(roomName)}`,
    // 'trp=true',
    `add=${encodeURIComponent(emailsList.join(','))}`,
    `src=${calendarId}`,
  ]

  return chunks.join('&')
}
