import { API_KEY } from '@shared/api/constants'
import { googleContentPeopleInstance } from '@shared/api/intances'
import { request } from '@shared/api/request'

export const getContactAddresses = (searchStr: string) =>
  request(googleContentPeopleInstance, {
    url: `/v1/people:batchGet?${searchStr}`,
    params: {
      personFields: 'names,emailAddresses,photos',
      key: API_KEY,
    },
  })
