import { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

type SessionInterceptorArg = {
  invalidTokenStatusCodes: number[]
  onInvalidToken: () => void
  onUnhandledError?: (e: AxiosError) => void
}

export const startSessionInterceptor = ({
  invalidTokenStatusCodes,
  onInvalidToken,
  onUnhandledError,
}: SessionInterceptorArg) => {
  const getInterceptor = (instances: AxiosInstance[]) => {
    const onSuccess = (request: AxiosResponse) => {
      return request
    }

    const onFailure = async (error?: AxiosError) => {
      const response = error?.response

      if (!response) {
        return Promise.reject(error)
      }

      const isInvalidToken = invalidTokenStatusCodes.includes(response.status)

      if (isInvalidToken) {
        onInvalidToken()
        return Promise.reject(error)
      }

      if (!isInvalidToken) {
        if (onUnhandledError) {
          onUnhandledError(error)
        }

        return Promise.reject(error)
      }
    }

    return instances.map(instance =>
      instance.interceptors.response.use(onSuccess, onFailure),
    )
  }

  return getInterceptor
}
