import { $token } from '@features/auth'
import { AxiosInstance, Method } from 'axios'

type RequestOptions = {
  url: string
  method?: Method
  params?: Record<string, string>
}

export const request = (instance: AxiosInstance, options: RequestOptions) => {
  const { url, method = 'GET', params } = options
  const accessToken = $token.getState()

  return instance({
    url,
    method,
    params,
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  })
}
