export type Room = {
  name: string
  aliases: string[]
  capacity: number
  exactMatchName: string
}

export const rooms: Room[] = [
  {
    name: '#1',
    aliases: ['#1', '№1', '1 переговорка', 'переговорка 1', '# 1', '№ 1'],
    capacity: 3,
    exactMatchName: '1',
  },
  {
    name: '#2',
    aliases: [
      '#2',
      '№2',
      '2 переговорка',
      'переговорка 2',
      '#2 TBI',
      '# 2',
      '№ 2',
    ],
    capacity: 3,
    exactMatchName: '2',
  },
  {
    name: '#3',
    aliases: ['#3', '№3', '3 переговорка', 'переговорка 3', '# 3', '№ 3'],
    capacity: 3,
    exactMatchName: '3',
  },
  {
    name: '#4',
    aliases: ['#4', '№4', '4 переговорка', 'переговорка 4', '# 4', '№ 4'],
    capacity: 3,
    exactMatchName: '4',
  },
  {
    name: '#5',
    aliases: ['#5', '№5', '5 переговорка', 'переговорка 5', '# 5', '№ 5'],
    capacity: 3,
    exactMatchName: '5',
  },
  {
    name: '#6',
    aliases: ['#6', '№6', '6 переговорка', 'переговорка 6', '# 6', '№ 6'],
    capacity: 3,
    exactMatchName: '6',
  },
  {
    name: '#7',
    aliases: ['#7', '№7', '7 переговорка', 'переговорка 7', '#7 ', '№ 7'],
    capacity: 3,
    exactMatchName: '7',
  },
  {
    name: 'зелёная',
    aliases: ['зеленая', 'зелёная', 'green'],
    capacity: 11,
    exactMatchName: 'зелёная',
  },
  {
    name: 'самолёт',
    aliases: ['самолёт', 'самолет'],
    capacity: 10,
    exactMatchName: 'самолёт',
  },
  {
    name: 'квадраты',
    aliases: ['подлодка', 'квадраты'],
    capacity: 9,
    exactMatchName: 'квадраты',
  },
  {
    name: 'облака',
    aliases: ['ракета', 'облака'],
    capacity: 8,
    exactMatchName: 'облака',
  },
  {
    name: 'космос',
    aliases: ['космос', 'склад'],
    capacity: 7,
    exactMatchName: 'космос',
  },
  {
    name: 'библиотека',
    aliases: ['библиотека'],
    capacity: 8,
    exactMatchName: 'библиотека',
  },
  {
    name: 'прованс',
    aliases: ['прованс'],
    capacity: 12,
    exactMatchName: 'прованс',
  },
  {
    name: 'лекторий',
    aliases: ['лекторий', 'лекториум'],
    capacity: 60,
    exactMatchName: 'лекторий',
  },
  {
    name: 'лица',
    aliases: ['лица', 'лицо'],
    capacity: 10,
    exactMatchName: 'лица',
  },
]
