import { getMe } from '@shared/api/methods'
import { TGetMeResponse } from '@shared/api/methods/types'
import { calendarKeys } from '@shared/api/query-keys'
import { QueryOptions } from '@shared/api/types'
import { useQuery } from '@tanstack/react-query'

export const useMe = (
  options?: QueryOptions<TGetMeResponse, ReturnType<typeof calendarKeys.me>>,
) => {
  return useQuery(calendarKeys.me(), () => getMe(), {
    staleTime: Infinity,
    ...options,
  })
}
