import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'

export type TRoomLink = {
  title: string
  url: string
}

export type TRoom = {
  title: string
  id: string
}

type Props = {
  time: string
  remaining?: number
  attendeesData: string[]
  rooms: TRoom[]
  onSubmit: (ids: string[]) => void
}

export const RoomCard = ({
  rooms,
  time,
  attendeesData,
  onSubmit,
  remaining,
}: Props) => {
  const [selectedRooms, setSelectedRooms] = useState<string[]>([])
  const [isMultiselect, setIsMultiselect] = useState(false)
  const hasCrossAttendees = attendeesData.length > 0

  useEffect(() => {
    if (selectedRooms.length === 0) {
      setIsMultiselect(false)
    }
  }, [selectedRooms])

  const renderAttendeesList = (data: string[]) => (
    <Stack direction="column" gap={0.5}>
      {data.map((attendee, index) => (
        <Typography key={index} variant="body2">
          {attendee}
        </Typography>
      ))}
    </Stack>
  )

  const onSubmitHandler = (ids: string[]) => {
    onSubmit(ids)
    setSelectedRooms([])
  }

  const renderRoomLink = ({ title, id }: TRoom) => (
    <Button
      key={id}
      variant={hasCrossAttendees ? 'outlined' : 'contained'}
      color={
        remaining
          ? 'success'
          : selectedRooms.includes(id)
          ? 'secondary'
          : 'primary'
      }
      sx={{
        borderColor: hasCrossAttendees ? 'divider' : 'primary.main',
      }}
      onClick={e => {
        if (e.shiftKey) {
          setIsMultiselect(true)
          setSelectedRooms(prev => [...prev, id])

          return
        }

        if (isMultiselect) {
          if (selectedRooms.includes(id)) {
            setSelectedRooms(prev => prev.filter(i => i !== id))
          } else {
            setSelectedRooms(prev => [...prev, id])
          }
        } else {
          onSubmitHandler([id])
        }
      }}
    >
      {title}
    </Button>
  )

  return (
    <Card
      sx={{
        minWidth: 275,
        mt: 2,
        bgcolor: hasCrossAttendees ? 'background.paper' : 'background.default',
      }}
      variant="outlined"
    >
      <CardContent>
        <Stack direction="row" gap={2} justifyContent="space-between">
          <Typography variant="body1" gutterBottom>
            {time}
            {remaining ? <strong> осталось {remaining} мин</strong> : null}
          </Typography>

          {selectedRooms.length > 0 ? (
            <Stack direction="row" gap={1}>
              <Button
                onClick={() => onSubmitHandler(selectedRooms)}
                variant="outlined"
              >
                Забронировать
              </Button>
              <Button onClick={() => setSelectedRooms([])} variant="outlined">
                Отмена
              </Button>
            </Stack>
          ) : (
            <Box height={36} />
          )}
        </Stack>

        {hasCrossAttendees && renderAttendeesList(attendeesData)}

        <Stack flexWrap="wrap" direction="row" gap={2} mt={2}>
          {rooms.map(renderRoomLink)}
        </Stack>
      </CardContent>
    </Card>
  )
}
