import { getContacts } from '@shared/api/methods'
import { TGetMemberNamesResponse } from '@shared/api/methods/types'
import { calendarKeys } from '@shared/api/query-keys'
import { QueryOptions } from '@shared/api/types'
import { useQuery } from '@tanstack/react-query'

export const useMemberNames = (
  name: string,
  options?: QueryOptions<
    TGetMemberNamesResponse,
    ReturnType<typeof calendarKeys.memberNames>
  >,
) =>
  useQuery(calendarKeys.memberNames(name), () => getContacts(name), {
    staleTime: Infinity,
    ...options,
    select: data => {
      const ids: string[] = data?.data?.memberResourceNames ?? []

      let tmpArr: string[] = []
      const resultArr = []

      ids.forEach((id, index) => {
        tmpArr.push('resourceNames=' + encodeURIComponent(id))
        if (!((index + 1) % 200)) {
          resultArr.push(tmpArr.join('&'))
          tmpArr = []
        }
      })
      if (tmpArr.length) {
        resultArr.push(tmpArr.join('&'))
      }

      return {
        ...data,
        data: {
          ...data.data,
          ids: resultArr,
        },
      }
    },
  })
