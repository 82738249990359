import {
  format,
  isBefore,
  isToday,
  differenceInMinutes,
  set,
  differenceInCalendarDays,
} from 'date-fns'
import { toDate, utcToZonedTime } from 'date-fns-tz'
import { ru } from 'date-fns/locale'

const kldTimeZone = 'Europe/Kaliningrad'

/**
 * Преобразует временной пояс передаваемой даты в калининградский
 *
 * Возвращает текущее время в Калининграде при отсутствии передаваемой даты
 *
 * @returns {Date} date
 */
export const getKldDate = (date?: Date | string) => {
  const d = toDate(date ?? new Date())
  const kldDate = utcToZonedTime(d, kldTimeZone)

  return kldDate
}

/**
 * Возвращает строку даты в формате
 *
 * 'Понедельник, 14 июля'
 * или
 * 'Сегодня, Понедельник, 14 июля'
 *
 * @param {Date} date
 */
export const getDateWeekDayMonth = (date: Date) => {
  const formattedDate = format(date, 'eeee, d MMMM', { locale: ru })
  const res = isToday(date) ? `сегодня, ${formattedDate}` : formattedDate

  return res
}

export const getRemainingMinutes = (
  currentTime: Date,
  startTime: string,
  endTime: string,
  date: Date,
) => {
  if (!isSameDay(date, currentTime)) return undefined

  const startDt = set(currentTime, {
    hours: Number(startTime.split(':')[0]),
    minutes: Number(startTime.split(':')[1]),
    seconds: 0,
    milliseconds: 0,
  })
  const endDt = set(currentTime, {
    hours: Number(endTime.split(':')[0]),
    minutes: Number(endTime.split(':')[1]),
    seconds: 0,
    milliseconds: 0,
  })
  if (isBefore(startDt, currentTime)) {
    return differenceInMinutes(endDt, currentTime)
  }
  return undefined
}

/**
 * Возвращает true, если указанная дата в прошлом
 * (за исключением сегодняшнего дня)
 *
 * @param {Date} date - дата на проверку
 * @param {Date} currentDate - текущая дата / дата отсчета
 */
export const isPastDate = (date: Date, currentDate?: Date) => {
  const today = new Date(currentDate ?? new Date())
  today.setHours(0, 0, 0, 0)

  return date < today
}

/**
 * Сравнивает две даты
 * Возвращает true, если это один и тот же день
 *
 * @param {Date} date
 * @param {Date} date
 */
export const isSameDay = (a: Date, b: Date) => {
  return differenceInCalendarDays(a, b) === 0
}
