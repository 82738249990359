import myMarkdownFile from '@md/description.md'
import { Button } from '@mui/material'
import { useEffect, useState } from 'react'
import MD from 'react-markdown'
import remarkGfm from 'remark-gfm'

type Props = {
  isFirstVisit?: boolean
}

export const Description = ({ isFirstVisit }: Props) => {
  const [markdown, setMarkdown] = useState('')
  const [isVisible, setIsVisible] = useState(isFirstVisit)
  useEffect(() => {
    fetch(myMarkdownFile)
      .then(response => response.text())
      .then(text => {
        setMarkdown(text)
      })
  })
  return markdown ? (
    <div style={{ marginTop: 30, overflowY: 'auto' }}>
      {!isFirstVisit ? (
        <Button onClick={() => setIsVisible(!isVisible)}>Инструкция</Button>
      ) : null}
      {isVisible ? <MD remarkPlugins={[remarkGfm]}>{markdown}</MD> : null}
    </div>
  ) : null
}
