import { FindRoomFormValues } from '@features/find-room/types'
import { TextField } from '@mui/material'
import { DesktopDatePicker } from '@mui/x-date-pickers'
import { isPastDate } from '@shared/lib'
import { Control, Controller } from 'react-hook-form'

type Props = {
  currentDate: Date
  control: Control<FindRoomFormValues>
  label?: string
}

const isValidDate = (date: Date | null) => {
  // @ts-expect-error
  return date && date instanceof Date && isFinite(date)
}

export const DatePicker = ({ currentDate, control, label }: Props) => (
  <Controller
    control={control}
    name="date"
    render={({ field: { value, onChange } }) => (
      <DesktopDatePicker
        disableMaskedInput={true}
        shouldDisableDate={date => isPastDate(date, currentDate)}
        label={label}
        inputFormat="EEEE, d MMMM"
        onChange={e => {
          if (isValidDate(e)) {
            onChange(e)
          }
        }}
        value={value}
        renderInput={params => {
          const patchedParams = { ...params }
          const prevValue = patchedParams.inputProps?.value || ''
          patchedParams.inputProps = {
            ...patchedParams.inputProps,
            readOnly: true,
            value: prevValue
              .replace('понедельник', 'пн')
              .replace('вторник', 'вт')
              .replace('среда', 'ср')
              .replace('четверг', 'чт')
              .replace('пятница', 'пт')
              .replace('суббота', 'сб')
              .replace('воскресенье', 'вс'),
          }
          return <TextField {...patchedParams} style={{ flex: 1 }} />
        }}
      />
    )}
  />
)
