type Options = {
  diffArray: number[]
  timeOptions: string[]
  siblings: { top: number; bottom: number }
}

export const getStartTimeSiblingsValues = ({
  diffArray,
  siblings,
  timeOptions,
}: Options) => {
  let topIndex: number | null = null
  let bottomIndex: number | null = null
  diffArray.forEach((value, index) => {
    if (value === siblings.top) {
      topIndex = index
      return
    }

    if (value === siblings.bottom) {
      bottomIndex = index
    }
  })

  const top = topIndex !== null ? timeOptions[topIndex] : null
  const bottom = bottomIndex !== null ? timeOptions[bottomIndex] : null

  return { top, bottom }
}
