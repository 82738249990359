export const clickLink = (href: string, isBlank?: boolean) => {
  const a = document.createElement('a')
  a.href = href
  if (isBlank) {
    a.target = '_blank'
  }
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}
