import axios from 'axios'

export const googlePeopleInstance = axios.create({
  baseURL: 'https://people.googleapis.com',
})
export const googleContentPeopleInstance = axios.create({
  baseURL: 'https://content-people.googleapis.com',
})

export const googleContentInstance = axios.create({
  baseURL: 'https://content.googleapis.com',
})
