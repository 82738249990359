import { Autocomplete, TextField } from '@mui/material'
import { Control, Controller } from 'react-hook-form'

import { FindRoomFormValues, TOption } from '../../../types'

type Props = {
  control: Control<FindRoomFormValues>
  options: TOption[]
}

export const ContactsAutocomplete = ({ control, options }: Props) => {
  return (
    <Controller
      control={control}
      name="selectedContacts"
      render={({ field: { value, onChange } }) => {
        const renderValue = value.map(item => {
          const nameChunks = item.label?.split(' ')
          const label = nameChunks
            ? `${nameChunks[0]} ${nameChunks[1]}`
            : item.label
          return {
            ...item,
            label,
          }
        })

        return (
          <Autocomplete
            style={{ flex: 1 }}
            multiple
            filterSelectedOptions
            options={options}
            value={renderValue}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            getOptionLabel={option => option?.label ?? 'Контакт'}
            onChange={(_, changedContacts) => onChange(changedContacts)}
            renderInput={params => (
              <TextField
                {...params}
                label="Участники"
                placeholder="Введите фамилию"
              />
            )}
          />
        )
      }}
    />
  )
}
