import { makeBookingLink } from '@features/find-room/lib'
import { Card, CardHeader, Typography } from '@mui/material'
import {
  clickLink,
  getDateWeekDayMonth,
  GetEmptyRoomsByDayResult,
  TEmail,
  getRemainingMinutes,
  isMobile,
  isSameDay,
} from '@shared/lib'
import { isBefore, set, subMinutes } from 'date-fns'

import { RoomCard, RoomCardSkeleton } from '../../molecules'

type Props = {
  date: Date
  currentDate: Date
  calendarId: string
  emails: TEmail[]
  isLoading: boolean
  roomList: GetEmptyRoomsByDayResult[]
  meetingType: string
}

const NoResultPlug = (
  <Typography textAlign="center" variant="body1" my={4}>
    Нет доступных переговорок. Измените&nbsp;параметры&nbsp;поиска.
  </Typography>
)

export const RoomCardsList = ({
  date,
  currentDate,
  roomList,
  calendarId,
  isLoading,
  emails,
  meetingType,
}: Props) => {
  const cardsList = roomList.map(({ startTime, endTime, attendees, rooms }) => {
    const endDt = set(date, {
      hours: Number(endTime.split(':')[0]),
      minutes: Number(endTime.split(':')[1]),
      seconds: 0,
      milliseconds: 0,
    })
    if (
      isSameDay(endDt, currentDate) &&
      isBefore(subMinutes(endDt, 3), currentDate)
    ) {
      return null
      // в некоторых кейсах в список могут попасть то время, которое уже прошло
      // от конца встречи отниамю 3 минут, чтобы если осталось так мало свободного времени,
      // переговорка уже не показывалась как доступная
    }
    const time = `${startTime} – ${endTime}`

    const onSubmitHandler = (ids: string[]) => {
      const url = makeBookingLink({
        calendarId,
        emails,
        endTime,
        roomName: ids.join(', '),
        selectedDate: date,
        startTime,
        meetingType,
      })

      if (isMobile && navigator.clipboard) {
        navigator.clipboard.writeText(ids.join(',')).then(() => {
          if (
            window.confirm(
              `Проверьте место и календарь перед созданием встречи в Google Calendar.\n\nНазвание переговорки "${ids.join(
                ', ',
              )}" мы заботливо скопировали в буфер обмена. Календарь должен быть "[KODE] график мероприятий"`,
            )
          ) {
            clickLink(url, true)
          }
        })
      } else {
        clickLink(url, true)
      }
    }

    const roomsArr = rooms.map(({ name }) => ({
      title: name,
      id: name,
    }))

    return (
      <RoomCard
        key={time}
        remaining={getRemainingMinutes(currentDate, startTime, endTime, date)}
        onSubmit={onSubmitHandler}
        rooms={roomsArr}
        time={time}
        attendeesData={attendees}
      />
    )
  })

  const renderResult = cardsList.length > 0 ? cardsList : NoResultPlug

  return (
    <Card
      sx={{ mt: 2.5, border: 'none', bgcolor: 'background.default' }}
      variant="outlined"
    >
      <CardHeader
        title={
          <Typography variant="h6">
            Свободные на {getDateWeekDayMonth(date)}:
          </Typography>
        }
        sx={{ p: 0, mb: 1 }}
      />
      {isLoading ? <RoomCardSkeleton /> : renderResult}
    </Card>
  )
}
