import { durationOptions } from '@features/find-room/constants'
import { styled, ToggleButton, ToggleButtonGroup } from '@mui/material'
import React from 'react'
import { Control, Controller } from 'react-hook-form'

import { FindRoomFormValues } from '../../../types'

const StyledToggleButton = styled(ToggleButton)({
  flex: 1,
  textTransform: 'uppercase',
  flexWrap: 'wrap',
  width: '100%',
})

type Props = {
  control: Control<FindRoomFormValues>
}

export const DurationToggle = ({ control }: Props) => {
  return (
    <Controller
      control={control}
      name="duration"
      render={({ field: { value, onChange } }) => (
        <ToggleButtonGroup
          value={value}
          exclusive
          onChange={(_, value) => {
            if (value !== null) {
              onChange(value)
            }
          }}
          style={{ flex: 1 }}
        >
          {durationOptions.map((durationOption, index) => (
            <StyledToggleButton
              key={durationOption}
              value={durationOption * 60 * 1000}
              color="primary"
            >
              {`${durationOption} мин`}
            </StyledToggleButton>
          ))}
        </ToggleButtonGroup>
      )}
    />
  )
}
