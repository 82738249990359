import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import MenuIcon from '@mui/icons-material/Menu'
import {
  AppBar,
  Box,
  Container,
  Divider,
  Drawer,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material'
import { ReactNode, useState } from 'react'

type Props = {
  drawerContent: ReactNode
  children: ReactNode
}
export const PageTemplate = ({ children, drawerContent }: Props) => {
  const [open, setOpen] = useState(false)
  const toggleDrawer = () => {
    setOpen(!open)
  }

  return (
    <>
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            Доходный Дом-{process.env.REACT_APP_DOM_VERSION}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer onClose={toggleDrawer} variant="temporary" open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Stack justifyContent={'space-between'} flex={1}>
          {drawerContent}
          <div>
            <Divider />
            <Box p={3}>
              <Typography variant="body2" color="inherit">
                &copy; KODE LLC
                <br />
                <small>
                  @vs, @me, @ku, @vsm, @smi, @omo, @kv, @pn, @ina, @vb
                </small>
              </Typography>
              <div>
                <Typography variant="caption" color="text.secondary">
                  version: {process.env.REACT_APP_VERSION}
                </Typography>
              </div>
            </Box>
          </div>
        </Stack>
      </Drawer>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        {children}
      </Container>
    </>
  )
}
